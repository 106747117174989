<template>
  <div class="base-tabs">
    <div class="base-tabs__list">
      <div
        class="base-tabs__list-item"
        :class="{ active: activeTab === index }"
        v-for="(el, index) in baseTabsList"
        :key="index"
        @click="changeState(index, el)"
      >
        <div class="base-tabs__item" :class="{ active: activeTab === index }">
          {{ el }}
          <div class="base-tabs__item-border" :class="{ active: activeTab === index }"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import { defineComponent, ref } from "vue";

export default defineComponent({
 name: "BaseTabs",
  props: {
    baseTabsList: {
      type: Array,
      default: () => [],
    }
  },
  setup(_, {emit}) {
    const activeTab = ref(0);
    const changeState = (activeIndex, activeElement) => {
      activeTab.value = activeIndex;
      emit("selectTab", activeElement);
    }
    return {
      activeTab,
      changeState
    }
  }
})
</script>
<style lang="scss" scoped>
@import "@/styles/ui/breakPoints";

.base-tabs {
  position: relative;
  margin: 44px 35px 0 35px;
  color: #cbd5e1;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    display: block;
    height: 2px;
    width: 100%;
    background-color: #e2e8f0;
  }

  &__list {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 32px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 5px;
      margin-bottom: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;

      &:hover {
        background: #d0d0d0;
      }
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #b2b2b2;
    }
  }

  &__list-item {
    position: relative;
    cursor: pointer;
    font-family: Inter, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    &.active {
      color: #0663c7;
    }
  }
  &__border {
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: #e2e8f0;
  }
  &__item-border {
    position: absolute;
    background-color: transparent;
    bottom: 0;
    height: 1px;
    width: 100%;
    z-index: 1;
    &.active {
      background-color: #0663c7;
    }
  }
  &__item {
    padding-bottom: 6px;
    color: var(--color-neutral-300);
    width: max-content;
    &.active {
      color: var(--color-primary-main);
    }
  }
  &__item:hover {
    color: #64748b;
    .base-tabs__item-border {
      background-color: #64748b;
    }
  }

  @include mobile-only {
    margin: 0;

    &:after {
      bottom: 9px;
    }
  }
}
</style>
