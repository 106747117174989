<template>
  <modal
    name="shift-edit"
    max-width="400px"
    :is-loading="isLoading"
    @hiddenModal="handleCloseModal"
    @update:payload="handlePayloadUpdate"
    @submit="apply"
  >
    <template #title="{ payload }">
      <div v-if="payload" class="shift-edit-modal__header">
        <div class="shift-edit-modal__title">
          {{ createdWorkShift ? "Редактировать смену:" : "Добавить смену:" }}
          <span v-if="!createdWorkShift" class="color-neutral-600">
            {{ getDateWorkShift(localPayload.workShift.date).dayOfWeek }}
          </span>
          <span v-if="!createdWorkShift">{{ getDateWorkShift(localPayload.workShift.date).dateFormatted }}</span>
        </div>
        <div v-if="localPayload.workShift?.id" class="shift-edit-modal__header-wrapper">
          <div class="shift-edit-modal__title-date">
            <span class="color-neutral-600">{{ getDateWorkShift(localPayload.workShift.date).dayOfWeek }}</span>
            <span>{{ getDateWorkShift(localPayload.workShift.date).dateFormatted }}</span>
          </div>
          <div class="shift-edit-modal__additional-info">
            <base-tag v-if="localPayload.workShift.turnOrder === 0" />
            <span>{{ localPayload.workShift?.job?.job_icon }}</span>
          </div>
        </div>
      </div>
    </template>
    <template #default>
      <div v-if="localPayload" class="shift-edit-modal__content-wrapper">
        <div class="shift-edit-modal__field-wrapper">
          <div class="base-input">
            <label class="base-input__label flex" for="input">
              <span class="base-input__label-text-wrapper flex">
                <span class="base-input__label-text">Выберете тип смены</span>
              </span>
            </label>
            <div class="base-input__wrapper shift-edit-modal__buttons-wrapper">
              <base-button
                class="shift-edit-modal__button"
                :class="{ __primary: workShiftStatus === TURN_FREE_VAL }"
                is-new
                @click="changeWorkShiftStatus(TURN_FREE_VAL)"
              >
                Свободная
              </base-button>
              <base-button
                class="shift-edit-modal__button"
                :class="{ __primary: workShiftStatus === TURN_CLOSE_VAL }"
                is-new
                @click="changeWorkShiftStatus(TURN_CLOSE_VAL)"
              >
                Закрепленная
              </base-button>
            </div>
          </div>
          <people-selector
            v-if="
              (workShiftStatus !== TURN_FREE_VAL && localPayload.workShift?.id) || workShiftStatus === TURN_CLOSE_VAL
            "
            label="Сотрудник"
            filter="work"
            :id_peopleIn="localPayload.workShift?.employee?.id"
            @change="selectPeople"
          />
          <div class="shift-edit-modal__date">
            <time-select
              label="Час нач."
              :time-data="timeData"
              :type-time="TIME_TYPE.BEGIN"
              :option-list-hours="HOURS"
              :option-list-minutes="MINUTES"
              :selected-hours="localPayload.workShift?.hourBegin"
              :select-minutes="localPayload.workShift?.minuteBegin"
              @update:selectedHours="currentBeginHours"
              @update:selectedMinutes="currentBeginMinutes"
            />
            <time-select
              label="Час кон."
              :time-data="timeData"
              :type-time="TIME_TYPE.END"
              :option-list-hours="HOURS"
              :option-list-minutes="MINUTES"
              :selected-hours="localPayload.workShift?.hourEnd"
              :select-minutes="localPayload.workShift?.minuteEnd"
              @update:selectedHours="currentEndHours"
              @update:selectedMinutes="currentEndMinutes"
            />
          </div>
          <!--          todo сделать отдельный компонент -->
          <div v-if="showWarningInfo" class="shift-edit-modal__info-block __warning">
            <div class="shift-edit-modal__info-block-title h3-mobile">
              <base-icon :path="'Warning.svg'" />
              <span>{{ errorMessage }}</span>
            </div>
          </div>
          <template v-if="workShiftStatus !== TURN_FREE_VAL">
            <base-switch
              label="Дополнительные часы"
              :value="showAdditionalTimeValue"
              @showAdditionalTime="showAdditionalTime"
            />
            <base-select
              v-if="showAdditionalTimeValue"
              label="Дополнительно отработал:"
              :value="localPayload?.workShift?.timeAdd"
              :option-list="additionalTime"
              @change="selectAdditionalTime"
            />
          </template>
          <base-button
            v-if="createdWorkShift"
            class="shift-edit-modal__delete-shift __red"
            is-new
            small
            secondary
            @click="deleteShift"
          >
            Удалить смену
          </base-button>
          <base-switch
            v-if="createdWorkShift"
            label="Показать историю изменения"
            @showAdditionalTime="showHistoryChanges"
          />
          <history-changes v-if="showHistoryChangesValue" :history-changes="historyLog" />
        </div>
      </div>
    </template>
  </modal>
</template>

<script setup>
import Modal from "@/components/Modal/Modal.vue";
import { computed, ref, watch } from "vue";
import { TURN_CLOSE_VAL, TURN_FREE_VAL } from "@/components-js/turns";
import { request } from "@/components-js/requestSrv";
import { formattedDate } from "@/components-js/dateFormat";
import PeopleSelector from "@/components/PeopleSelector.vue";
import BaseTag from "@/components/BaseTag/BaseTag.vue";
import BaseSelect from "@/components/BaseSelect/BaseSelect.vue";
import TimeSelect from "@/components/TimeSelect/TimeSelect.vue";
import BaseButton from "@/components/BaseButton/BaseButton.vue";
import BaseSwitch from "@/components/BaseSwitch/BaseSwitch.vue";
import HistoryChanges from "@/views/SchedulePage/components/ShiftEditModal/components/HistoryChanges/HistoryChanges.vue";
import emitter from "@/lib/helpers/emitter";
import { EVENTS } from "@/const";
import BaseIcon from "@/components/BaseIcon";
import { TIME_TYPE } from "@/views/SchedulePage/helpers";
import { useStore } from "vue2-helpers/vuex";

const store = useStore();
const localPayload = ref(null);
const HOURS = ["7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "00"];
const MINUTES = ["00", "15", "30", "45"];
const showAdditionalTimeValue = ref(false);
const showHistoryChangesValue = ref(false);
const showWarningInfo = ref(false);
const historyLog = ref([]);
const workShiftStatus = ref(TURN_FREE_VAL);
const peoples = ref([]);
const emits = defineEmits(["updateShift", "handleCloseModal"]);
const additionalTime = [
  { id: "0", nickname: "+0ч" },
  { id: "1", nickname: "+1ч" },
  { id: "2", nickname: "+2ч" },
  { id: "3", nickname: "+3ч" },
  { id: "4", nickname: "+4ч" },
  { id: "5", nickname: "+5ч" },
  { id: "6", nickname: "+6ч" },
];

const additionalTimeValue = ref("0");
const errorMessage = ref("Смены пересекаются!");
const isLoading = ref(true);

const jobList = computed(() => store.state.jobList);
const peopleList = computed(() => store.state.peopleList);

const handlePayloadUpdate = newPayload => {
  localPayload.value = newPayload;
  showAdditionalTimeValue.value = Boolean(localPayload.value?.workShift?.timeAdd);
  isLoading.value = false;
  showWarningInfo.value = false;
};

const handleCloseModal = v => {
  showHistoryChangesValue.value = false;
  emits("handleCloseModal", v);
};

const currentBeginHours = value => {
  localPayload.value.workShift.hourBegin = Number(value);
};
const currentBeginMinutes = value => {
  localPayload.value.workShift.minuteBegin = Number(value);
};
const currentEndHours = value => {
  localPayload.value.workShift.hourEnd = Number(value);
};
const currentEndMinutes = value => {
  localPayload.value.workShift.minuteEnd = Number(value);
};

const timeData = computed(() => ({
  begin: {
    hour: localPayload.value.workShift.hourBegin,
    minute: localPayload.value.workShift.minuteBegin,
  },
  end: {
    hour: localPayload.value.workShift.hourEnd,
    minute: localPayload.value.workShift.minuteEnd,
  },
}));

const getPeoples = async () => {
  peoples.value = await request("/api/user/listwork");
  peoples.value.sort((a, b) => {
    if (!a.nickname) a.nickname = a.surname + " " + a.name;
    if (!b.nickname) b.nickname = b.surname + " " + b.name;
    if (a.nickname > b.nickname) return 1;
    if (a.nickname < b.nickname) return -1;
    return 0;
  });
};

const changeWorkShiftStatus = async value => {
  workShiftStatus.value = value;
  localPayload.value.workShift.status = value;
  await getPeoples();
  const hasPeople = localPayload.value.workShift.employee.id;
  localPayload.value.workShift.employee.id = hasPeople
    ? localPayload.value.workShift?.employee?.id
    : peoples.value[0].id;

  await selectPeople(peoples.value[0].id);
};

const showAdditionalTime = value => {
  showAdditionalTimeValue.value = value;
};
const showHistoryChanges = async value => {
  showHistoryChangesValue.value = value;
  if (value) {
    historyLog.value = [];
    const result = await request(`/api/turn/history`, "POST", {
      id_turn: localPayload.value?.workShift?.id,
    });
    historyLog.value.push(...result);
  }
};

const createdWorkShift = computed(() => {
  return localPayload.value.workShift?.id;
});

const selectPeople = async v => {
  localPayload.value.workShift.employee.id = v;
  const people = peopleList.value.find(item => item.id === Number(v));
  localPayload.value.workShift.job = jobList.value.find(item => item.id === Number(people.id_job));
};

const selectAdditionalTime = v => {
  additionalTimeValue.value = v.target.value;
};

const deleteShift = async () => {
  // DB request
  const res = await request(`/api/turn/delete`, "DELETE", {
    id_turn: localPayload.value.workShift?.id,
    dataSource: "",
  });
  additionalTimeValue.value = "0";
  emits("updateShift");
  emitter.emit(EVENTS.HIDE_MODAL);
  // delete fault
  if (res.affectedRows === 0) {
    // add ERROR effect !!!
    return;
  }
  // multuturn for
  // this.$root.$emit("turnUpdate", "delete");
  // windowClose(props.turn, "del");
};

const apply = async () => {
  // // check, that human is selected
  // if (props.turn.status !== TURN_FREE_VAL && !props.turn.id_people) {
  //   // this.$refs.human.classList.add("warn-border");
  //   // setTimeout(() => this.$refs.human.classList.remove("warn-border"), 300);
  //   return;
  // }
  //
  // // check for correct time interval include minutes
  // if (
  //   parseInt(props.turn.hour_begin) * 60 + parseInt(props.turn.minute_begin) >=
  //   parseInt(props.turn.hour_end) * 60 + parseInt(props.turn.minute_end)
  // ) {
  //   // this.$refs.hours.classList.add("warn-border");
  //   // setTimeout(() => {
  //   //   this.$refs.hours.classList.remove("warn-border");
  //   // }, 500);
  //   return;
  // }
  //
  // // disable button for prevent turn dupliction
  // // this.$refs.applyButton.disabled = true;
  //
  // // check for intersection with other turns
  // if ((await turnMatches()) > 0) {
  //   // this.$refs.applyButton.classList.add("warn");
  //   // setTimeout(() => this.$refs.applyButton.classList.remove("warn"), 300);
  //   errMessage.value = "пересечение с другой сменой";
  //   // setTimeout(() => (this.errMessage = null), 1000);
  //   // this.$refs.applyButton.disabled = false;
  //   return;
  // }
  //
  // if (localPayload.value.workShift.id_turn) {
  //   //update record
  //   props.turn.dataSource = props.dataSource; // add postfix for pointadmin draft
  // console.log(localPayload.value.workShift);
  isLoading.value = true;
  const isFreeShift = workShiftStatus.value === TURN_FREE_VAL;
  const is24Hour = localPayload.value.workShift?.hourEnd === 0;
  const updatingShift = {
    dataSource: "",
    id_turn: localPayload.value.workShift?.id ?? null,
    id_point: localPayload.value.workShift?.pointId ?? 0,
    id_people: isFreeShift ? null : localPayload.value.workShift?.employee?.id,
    id_job: isFreeShift ? null : localPayload.value.workShift?.job?.id ?? null,
    turn_date: localPayload.value.workShift?.date ?? "",
    hour_begin: localPayload.value.workShift?.hourBegin ?? 0,
    hour_end: is24Hour ? 24 : localPayload.value.workShift?.hourEnd ?? 0,
    time_add: isFreeShift ? 0 : showAdditionalTimeValue.value ? additionalTimeValue.value : 0,
    time_add_note: isFreeShift ? null : localPayload.value.workShift?.timeAddNote ?? null,
    turn_rate: isFreeShift ? null : localPayload.value.workShift?.job?.job_rate ?? null,
    turn_order: localPayload.value.workShift?.turnOrder ?? 0,
    orderAbs: 2,
    minute_begin: localPayload.value.workShift?.minuteBegin ?? 0,
    minute_end: localPayload.value.workShift?.minuteEnd ?? 0,
    status: localPayload.value.workShift?.status ?? "free",
  };
  const validation = await request(`/api/turn/validationextended`, "POST", updatingShift);
  if (validation.length === 0) {
    const res = await request(
      `/api/turn/${localPayload.value.workShift?.id ? "update" : "insert"}`,
      "POST",
      updatingShift
    );
    if (res.affectedRows) {
      localPayload.value.workShift.id = res.insertId;
      additionalTimeValue.value = "0";
      emits("updateShift");
      emitter.emit(EVENTS.HIDE_MODAL);
      showWarningInfo.value = false;
    } else if (res.sqlMessage) {
      errorMessage.value = "Internal Error! Try again.\n" + res.sqlMessage;
      showWarningInfo.value = true;
      isLoading.value = false;
    }
    // window.location.reload();
  } else {
    showWarningInfo.value = true;
  }

  //   delete props.turn.dataSource; // and remove it
  //   // multuturn for
  //   // this.$root.$emit("turnUpdate", "update");
  //   // this.$refs.applyButton.disabled = false;
  //   if (res.affectedRows) windowClose(props.turn, "upd");
  // } else {
  //   // insert record
  //   props.turn.dataSource = props.dataSource; // add postfix for pointadmin draft
  // console.log("localPayload.value.workShift", localPayload.value.workShift);
  // const res = await request(`/api/turn/insert`, "POST", updatingShift);
  // console.log('insert record', res);

  //   delete props.turn.dataSource; // and remove it
  //   // this.$refs.applyButton.disabled = false;
  //   if (res.affectedRows === 1 && res.insertId) {
  //     // multuturn for
  //     // this.$root.$emit("turnUpdate", "insert");
  // localPayload.value.workShift.id_turn = res.insertId;
  //     windowClose(props.turn, "add");
  // }
  // }
  isLoading.value = false;
};

watch(localPayload, newPayload => {
  workShiftStatus.value = newPayload?.workShift?.employee?.id ? TURN_CLOSE_VAL : TURN_FREE_VAL;
});

// const selectedDateBegin = ref(props.dateBegin);
// const selectedDateEnd = ref(props.dateEnd);

const getDateWorkShift = date => {
  if (date) {
    return formattedDate(new Date(date));
  }
  return {};
};

//
//   showFlag: Boolean,
//   turn: Object,
//   people: Array,
//   jobs: Array,
//   type: null,
//   dataSource: String,
// });
// const show = ref(false);
//
// const errMessage = ref(null);
// const timeAddFlag = ref(false);
// const turnHistory = ref([]);
// const route = ref(null);
// const id_peoplePrev = ref(null);
//
// const jobName = computed(() => {
//   return props.jobs.find(item => item.id === props.turn.id_job)?.job_name ?? "";
// });
//
// watch(toRef(props, "showFlag"), newVal => {
//   turnHistory.value = [];
//
//   // window appeared, set default values
//   if (newVal) {
//     document.addEventListener("keyup", keyPressHandler);
//     // time_add controls
//     props.turn.time_add ? (timeAddFlag.value = true) : (timeAddFlag.value = false);
//
//     // if (this.turn.status == TURN_FREE_VAL) {
//     //   this.setFreeTurn();
//     // }
//
//     // window first open
//     if (props.turn.status == null) {
//       if (props.turn.id_people) {
//         props.turn.status = TURN_CLOSE_VAL;
//       } else {
//         setFreeTurn();
//       }
//     }
//
//     // existing turn opened
//     if (props.turn.status === "closed") {
//       if (props.turn.id_people) id_peoplePrev.value = props.turn.id_people;
//       if (!props.turn.id_people && id_peoplePrev.value) peopleSelectorHandler(id_peoplePrev.value);
//     }
//   } else {
//     document.removeEventListener("keyup", keyPressHandler);
//   }
// });
// watch(timeAddFlag, newVal => {
//   if (!newVal) {
//     props.turn.time_add = 0;
//   }
// });
//
// //   "turn.id_job": function(newVal) {
// //     if (this.turn.status === TURN_FREE_VAL || !newVal) return;
// //     this.turn.turn_rate = this.jobs.find(el => el.id === newVal).job_rate;
// //   },
// // },
//
// const turnHistoryGet = async () => {
//   if (turnHistory.value.length) {
//     turnHistory.value = [];
//     return;
//   }
//   turnHistory.value = await request(`/api/${route.value}/history`, "POST", {
//     id_turn: props.turn.id_turn,
//   });
// };
//
// const keyPressHandler = evt => {
//   if (evt.code === "Escape") windowClose(0, null);
//   if (evt.code === "Enter") Apply();
// };
//
// const peopleSelectorHandler = id_people => {
//   if (id_people === null) return;
//   props.turn.id_people = id_people;
//   id_peoplePrev.value = id_people;
//   if (props.people.length) props.turn.id_job = props.people.find(el => el.id === id_people).id_job;
// };
//
// const setFreeTurn = () => {
//   props.turn.status = TURN_FREE_VAL;
//   props.turn.id_people = null;
//   props.turn.id_job = null;
//   props.turn.turn_rate = null;
// };
//
// const setCloseTurn = () => {
//   props.turn.status = TURN_CLOSE_VAL;
//   peopleSelectorHandler(id_peoplePrev.value);
// };
//
// const Apply = async () => {
//   // check, that human is selected
//   if (props.turn.status !== TURN_FREE_VAL && !props.turn.id_people) {
//     // this.$refs.human.classList.add("warn-border");
//     // setTimeout(() => this.$refs.human.classList.remove("warn-border"), 300);
//     return;
//   }
//
//   // check for correct time interval include minutes
//   if (
//     parseInt(props.turn.hour_begin) * 60 + parseInt(props.turn.minute_begin) >=
//     parseInt(props.turn.hour_end) * 60 + parseInt(props.turn.minute_end)
//   ) {
//     // this.$refs.hours.classList.add("warn-border");
//     // setTimeout(() => {
//     //   this.$refs.hours.classList.remove("warn-border");
//     // }, 500);
//     return;
//   }
//
//   // disable button for prevent turn dupliction
//   // this.$refs.applyButton.disabled = true;
//
//   // check for intersection with other turns
//   if ((await turnMatches()) > 0) {
//     // this.$refs.applyButton.classList.add("warn");
//     // setTimeout(() => this.$refs.applyButton.classList.remove("warn"), 300);
//     errMessage.value = "пересечение с другой сменой";
//     // setTimeout(() => (this.errMessage = null), 1000);
//     // this.$refs.applyButton.disabled = false;
//     return;
//   }
//
//   if (props.turn.id_turn) {
//     //update record
//     props.turn.dataSource = props.dataSource; // add postfix for pointadmin draft
//     const res = await request(`/api/${route.value}/update`, "POST", props.turn);
//     delete props.turn.dataSource; // and remove it
//     // multuturn for
//     // this.$root.$emit("turnUpdate", "update");
//     // this.$refs.applyButton.disabled = false;
//     if (res.affectedRows) windowClose(props.turn, "upd");
//   } else {
//     // insert record
//     props.turn.dataSource = props.dataSource; // add postfix for pointadmin draft
//     const res = await request(`/api/${route.value}/insert`, "POST", props.turn);
//     delete props.turn.dataSource; // and remove it
//     // this.$refs.applyButton.disabled = false;
//     if (res.affectedRows === 1 && res.insertId) {
//       // multuturn for
//       // this.$root.$emit("turnUpdate", "insert");
//       props.turn.id_turn = res.insertId;
//       windowClose(props.turn, "add");
//     }
//   }
// };
//
// const windowClose = (turnId, action) => {
//   // this.$emit("hide", turnId, action);
// };
//
// const turnMatches = async () => {
//   props.turn.dataSource = props.dataSource; // add postfix for pointadmin draft
//   const data = await request(`/api/${route.value}/validation`, "POST", props.turn);
//   delete props.turn.dataSource; // and remove it
//   return parseInt(data[0].cnt);
// };
//
// const turnStatusRotate = () => {
//   props.turn.status = props.turn.status === TURN_CLOSE_VAL ? TURN_FREE_VAL : TURN_CLOSE_VAL;
//   if (props.turn.status === TURN_FREE_VAL) {
//     setFreeTurn();
//   } else {
//     setCloseTurn();
//   }
// };
//
// const turnDelete = async () => {
//   // DB request
//   if (!confirm("Удалить смену?")) return;
//   const res = await request(`/api/${route.value}/delete`, "DELETE", {
//     id_turn: props.turn.id_turn,
//     dataSource: props.dataSource,
//   });
//   // delete fault
//   if (res.affectedRows === 0) {
//     // add ERROR effect !!!
//     return;
//   }
//   // multuturn for
//   // this.$root.$emit("turnUpdate", "delete");
//   windowClose(props.turn, "del");
// };
</script>

<style scoped lang="scss">
@import "@/styles/ui/fontSize";

.shift-edit-modal {
  &__header {
    display: flex;
    flex-direction: column;
    gap: var(--sp-2);
  }

  &__title {
    @include add-font-face("h4");
  }

  &__header-wrapper {
    display: flex;
    gap: var(--sp-6);
  }

  &__title-date {
    display: flex;
    gap: var(--sp-1);
    @include add-font-face("body-1");
  }

  &__additional-info {
    display: flex;
    gap: var(--sp-3);
  }

  &__content-wrapper {
    max-width: 400px;
    margin-top: var(--sp-6);
  }

  &__field-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__date {
    display: flex;
    justify-content: space-between;
    gap: var(--sp-6);
  }

  &__buttons-wrapper {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    background: var(--Primary-bg, #dbedff);
    gap: 4px;
    padding: 4px;
  }

  &__button {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    border: none;
    background: var(--Primary-bg, #dbedff);
    cursor: pointer;

    &:first-child {
      margin-right: 12px;
    }

    &.__primary {
      border-radius: 4px;
      background: var(--Primary-Main, #0663c7);
      color: white;
    }
  }

  &__delete-shift {
    background: var(--color-red-light-bg);
    color: var(--color-red-dark-main);
    &:hover {
      box-shadow: inset 0 0 0 2px var(--Red-Dark-main, #ad0015);
    }
  }

  &__info-block {
    display: flex;
    border-radius: 5px;
    background: var(--color-red-light-bg);
    padding: var(--sp-3);
  }
  &__info-block-title {
    display: flex;
    gap: var(--sp-3);
    span {
      color: var(--color-red-dark-main);
    }
  }

  @include mobile-only {
    &__content-wrapper {
      margin-top: var(--sp-6);
    }
  }
}
</style>
