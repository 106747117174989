<template>
  <div id="app" :class="{ 'app-old': !isNewDesign, 'app-new': isNewDesign }">
    <!--    todo Сделать одельный компонент mobileHeader-->
    <div v-if="showMobileHeader" class="mobile-header">
      <base-icon class="mobile-header__list" :path="'navBar/List.svg'" @click="openMenu(false)" />
      <router-link to="Today" class="mobile-header__title-label">
        <span class="mobile-header__title-brew">Brew</span>
        <span class="mobile-header__title-ninja">Ninja</span>
      </router-link>
      <div>
        <base-avatar
          v-if="loading"
          class="mobile-header__avatar"
          size="32"
          :screen-size-dependency="true"
          :show-avatar-info="false"
          :info="user"
        />
      </div>
    </div>
    <!--    todo Сделать одельный компонент navbar-->
    <div v-if="showNavBar" id="nav" class="nav title noselect" :class="{ __mobile: isMobile }">
      <div class="nav__title">
        <base-icon class="nav__close" v-if="isMobile" :path="'Close.svg'" @click="openMenu(false)" />
        <router-link v-if="isMobile" to="Today" class="mobile-header__title-label">
          <span class="mobile-header__title-brew">Brew</span>
          <span class="mobile-header__title-ninja">Ninja</span>
        </router-link>
        <div v-else class="nav__title-label">
          <div class="nav__title-ninja">Brew Ninja</div>
          <div class="nav__title-brew">{{ user.business }}</div>
        </div>
      </div>
      <div v-if="!isMobile" class="nav__divider my-0"></div>
      <div class="nav__wrapper">
        <div class="nav__overlay" @click="openMenu(false)"></div>
        <template v-for="item in Links">
          <div v-if="item.showFlag" :key="item.to" class="nav__item" @click="openMenu(item.dropdownMenu)">
            <div v-if="['adminRoom', 'logout'].includes(item.name) && !isMobile" class="nav__divider"></div>
            <base-button
              class="nav__item-button"
              :to="item.to"
              :key="item.to"
              :start-icon-path="item.startIcon"
              :dropdown-menu="item.dropdownMenu"
              :rounded="!isMobile"
              :content-after="!isMobile"
              :icon-size="isMobile ? '24' : '32'"
              :class="{ navlogout: item.to === '/logout' }"
              buttonMenu
              @closeMobileMenu="openMenu(false)"
            >
              <div class="nav__item-button-content">
                {{ item.title }}
                <span v-if="item.name === 'freeturns'" class="nav__item-button-badge">
                  {{ freeShiftCount }}
                </span>
              </div>
            </base-button>
          </div>
        </template>
        <div v-if="user.uid === 173" class="nav__item">
          <base-button
            class="nav__item-button"
            :rounded="!isMobile"
            :content-after="!isMobile"
            :start-icon-path="'info.svg'"
            :icon-size="isMobile ? '24' : '32'"
            buttonMenu
            @click="showReleaseInfo"
          >
            Версия продукта
          </base-button>
        </div>
      </div>
      <base-avatar class="nav__avatar" v-if="!isMobile" :info="user" />
    </div>
    <div class="wide" :class="{ '__full-width': currentRouteLogin }">
      <router-view />
    </div>
    <modal name="version-info" max-width="700px" title="Информация о релизе" hide-close-btn>
      <template #default="{payload}">
        <p>
          Время релиза:
          <span>{{ new Date(payload?.data?.dateTime).toLocaleDateString() }}</span>
        </p>
        <p>
          Кто релизил:
          <span>{{ payload?.data?.user }}</span>
        </p>
        <p>
          Ветка сборки:
          <span>{{ payload?.data?.branch }}</span>
        </p>
        <p>
          Хэш коммита сборки:
          <span>{{ payload?.data?.commitHash }}</span>
        </p>
        <base-button is-new small @click="copyVersionInfo(payload?.data)">Копировать данные</base-button>
      </template>
      <template #buttons-wrapper="{closeModal}">
        <div class="modal__buttons-wrapper">
          <base-button is-new tertiary small @click="closeModal">Закрыть</base-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, watch, onUnmounted, onUpdated } from "vue";
import { useRoute } from "vue2-helpers/vue-router";
import { useStore } from "vue2-helpers/vuex";
import { ACTION_TYPES, MUTATION_TYPES } from "@/store/const";
import { request, userDataGet } from "@/components-js/requestSrv";
import { DEVICE_TYPES } from "@/lib/helpers/checkDevice";
import { navLinks } from "@/config";
import BaseButton from "@/components/BaseButton";
import BaseIcon from "@/components/BaseIcon";
import BaseAvatar from "@/components/BaseAvatar";
import "./styles/app.scss";
import Modal from "@/components/Modal/Modal.vue";
import emitter from "@/lib/helpers/emitter";
import { EVENTS } from "@/const";

export default defineComponent({
  name: "App",
  components: { Modal, BaseAvatar, BaseIcon, BaseButton },
  async updated() {
    // todo переделать на глобальное хранилище vuex, в дальнейшем будет мешать переходу на vue3
    this.$root.user = await userDataGet();
  },
  setup() {
    const store = useStore();
    store.dispatch(ACTION_TYPES.LOGIN);
    const route = useRoute();

    const user = ref({});
    const loading = ref(false);
    const links_flag = ref(false);
    const isMenuOpen = ref(false);

    const currentRoute = computed(() => route.name);
    const currentRouteLogin = computed(() => route.name === "Login");
    const isMobile = computed(() => store?.state?.breakPoint === DEVICE_TYPES.MOBILE);
    const isTablet = computed(() => store?.state?.breakPoint === DEVICE_TYPES.TABLET);
    const isNewDesign = computed(() => store?.state?.isNewDesign);
    const showMobileHeader = computed(
      () => (isMobile.value || (!isNewDesign.value && isTablet.value)) && links_flag.value
    );
    const showNavBar = computed(() =>
      isMobile.value || (!isNewDesign.value && isTablet.value) ? isMenuOpen.value : links_flag.value
    );
    const Links = computed(() => navLinks);
    const freeShiftCount = ref(0);

    const showReleaseInfo = () => {
      const base64ToBytes = base64 => {
        const binString = atob(base64);
        return Uint8Array.from(binString, m => m.codePointAt(0));
      };

      const encodedData = document.querySelector('meta[name="version"]')?.content;
      const decodedData = !!encodedData ? new TextDecoder().decode(base64ToBytes(encodedData)) : undefined;

      if (!!decodedData) {
        console.info("data", JSON.parse(decodedData));
        emitter.emit(EVENTS.SHOW_MODAL, {
          name: "version-info",
          data: JSON.parse(decodedData),
        });
      }
    };

    const copyVersionInfo = data => {
      const tempInput = document.createElement("input");
      tempInput.value = JSON.stringify(data);

      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);

      alert("Данные о версии релиза скопированы");
    };

    const openMenu = hasItemDropDown => {
      if (!hasItemDropDown) {
        isMenuOpen.value = !isMenuOpen.value;
      }
    };

    const handleResize = () => {
      store.dispatch(ACTION_TYPES.GET_BREAK_POINT);
    };

    watch(currentRoute, async () => {
      try {
        user.value = await userDataGet();
      } finally {
        loading.value = true;
      }
      if (route.meta.showMenu) {
        //const rolesUser = await rolesGet();
        for (let l of Links.value) {
          if (l.hidden) continue;
          l.showFlag = l.roles.map(r => user.value.roles.includes(r)).includes(true);
          if (!user.value.brew && l.to === "/wiki") {
            l.showFlag = false;
          }
          if (l.paidFeature && !user.value.brew) {
            l.showFlag = false;
          }
          if (l.dropdownMenu && l.dropdownMenu.length > 0) {
            l.dropdownMenu = l.dropdownMenu.map(item => {
              if (item.paidFeature) {
                !!user.value.brew ? (item.showFlag = true) : (item.showFlag = false);
              } else {
                item.showFlag = item.roles.map(r => user.value.roles.includes(r)).includes(true);
              }
              return item;
            });
          }
          if (user.value.uid === -1) {
            l.showFlag = l.uid === -1;
          }
        }
      }
      links_flag.value = route.meta.showMenu;
    });

    onUpdated(async () => {
      store.commit(MUTATION_TYPES.SET_DESIGN_FLAG, route?.meta?.isNewDesign);
      const freeShifts = await request("/api/turn/listfree", "POST");
      freeShiftCount.value = freeShifts.length;
    });

    onMounted(async () => {
      const runOnKeys = (func, ...codes) => {
        let pressed = new Set();

        document.addEventListener("keydown", function(event) {
          pressed.add(event.code);
          for (let code of codes) {
            if (!pressed.has(code)) {
              return;
            }
          }
          pressed.clear();

          func();
        });

        document.addEventListener("keyup", function(event) {
          pressed.delete(event.code);
        });
      };
      runOnKeys(showReleaseInfo, "Enter", "ControlLeft");

      window.addEventListener("resize", handleResize);
      handleResize();
      await store.dispatch(ACTION_TYPES.GET_POINT_LIST);
      const freeShifts = await request("/api/turn/listfree", "POST");
      freeShiftCount.value = freeShifts.length;
      try {
        user.value = await userDataGet();
      } finally {
        loading.value = true;
      }
      if (route.meta.showMenu) {
        for (let l of Links.value) {
          if (l.hidden) continue;
          l.showFlag = l.roles.map(r => user.value.roles.includes(r)).includes(true);
          if (!user.value.brew && l.to === "/wiki") {
            l.showFlag = false;
          }
          if (l.paidFeature && !user.value.brew) {
            l.showFlag = false;
          }
          if (l.dropdownMenu && l.dropdownMenu.length > 0) {
            l.dropdownMenu = l.dropdownMenu.map(item => {
              if (item.paidFeature) {
                !!user.value.brew ? (item.showFlag = true) : (item.showFlag = false);
              } else {
                item.showFlag = item.roles.map(r => user.value.roles.includes(r)).includes(true);
              }
              return item;
            });
          }
          if (user.value.uid === -1) {
            l.showFlag = l.uid === -1;
          }
        }
      }
      links_flag.value = route.meta.showMenu;
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      user,
      Links,
      loading,
      isNewDesign,
      isMobile,
      links_flag,
      freeShiftCount,
      isMenuOpen,
      currentRouteLogin,
      showNavBar,
      showMobileHeader,
      openMenu,
      showReleaseInfo,
      copyVersionInfo,
    };
  },
});
</script>
<style lang="scss">
@import "@/styles/oldStyles";
@import "@/styles/newStyles";

.app-old {
  @include oldStyle;
}

.app-new {
  @include newStyle;
}

.nav {
  &__item-button {
    .base-button.__button-menu.router-link-active {
      .nav__item-button-badge {
        background: var(--Primary-light-Main, #4196f0);
        color: var(--Basic-White, #fff);
      }
    }
  }

  &__item-button-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__item-button-badge {
    border-radius: 5px;
    background: var(--Primary-bg, #dbedff);
    color: var(--color-neutral-800);
    width: 24px;
    height: 24px;
    text-align: center;

    /* Button,Link/Desktop/Small */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 114.286% */
  }
}

.app-old {
  .nav__item-button-content {
    flex-direction: row;
  }
}
</style>
