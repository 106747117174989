<template>
  <div class="base-dropdown-button">
    <div class="base-dropdown-button__dropdown-menu px-2" v-for="(item, index) in dropdownMenuList" :key="index">
      <button
        v-if="item.showFlag"
        :is="item.to ? 'router-link' : item.isHref ? 'a' : 'button'"
        :to="item.to"
        class="base-button"
        :class="{ '__button-menu': buttonMenu, rounded: rounded }"
        :disabled="item.disabled"
        @click.native="$emit('closeMobileMenu')"
      >
        <template>
          <base-icon v-if="item.startIcon" class="base-button__start-icon" :path="item.startIcon" />
          {{ item.title }}
        </template>
      </button>
    </div>
  </div>
</template>
<script lang="js">
import { computed, defineComponent } from "vue";
import BaseIcon from "@/components/BaseIcon";

export default defineComponent({
  name: "DropdownMenu",
  components: { BaseIcon },
  props: {
    dropdownMenu: {
      type: Array,
      default: () => [],
    },
    buttonMenu: {
      type: Boolean,
      default: false,
    },
    isDropdownOpen: {
      type: Boolean,
      required: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    }
  },
  setup(props) {
    const dropdownMenuList = computed(() => props.dropdownMenu)
    return {
      dropdownMenuList
    }
  }
})
</script>
<style lang="scss" scoped>
.base-dropdown-button {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  background: #0f172a;
  border-radius: 0 0 5px 5px;

  ::v-deep.base-button {
    &.__button-menu {
      &.router-link-active.rounded {
        background: #334155;
        border-radius: 5px;
      }

      &.rounded:hover {
        border-radius: 5px;
      }

      &.router-link-active {
        background: #334155;
        border-radius: 0;
      }

      &.router-link-active:after {
        content: "";
        background: transparent;
      }
    }
  }
}
</style>
